.world__container {
  position: relative;

  transition: all 0.5s ease-in-out;
}

.world__map-transition {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transition: all 0.5s ease-in-out;

  background-color: black;
}