.inventory-dialog__container {
  flex: 1;
  align-items: center;

  .inventory-dialog__child {
    justify-content: flex-start;

    .inventory-dialog__backpack {
      height: 280px;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}