.endless-start__title {
  font-size: 26px;
  justify-content: center;

  padding: 14px 0 0;
  margin-left: 26px;
}

.endless-start__text {
  flex: 1;

  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;

  justify-content: space-between;

  padding: 48px 32px 0;
}

.endless-start__button {
  padding: 16px 0;

  align-items: center;
}
