.map__padding--bottom {
  position: absolute;
  bottom: -200px;
}

.map__padding--top {
  position: absolute;
  top: -200px;
}

.map__padding--left {
  position: absolute;
  top: -200px;
  left: -200px;
}

.map__padding--right {
  position: absolute;
  top: -200px;
  right: -200px;
}
