.stats__container {
  justify-content: center;

  transition: background .5s ease-in-out;

  cursor: default;
  user-select: none;
}

.stats__row--spacing {
  padding-bottom: 15px;
}

.stats__text--spacing {
  padding-right: 15px;
}

.stats__text--level {
  color: var(--blue);
}

.stats__text--gold {
  color: var(--orange);
}

.stats__text--damage {
  color: var(--light-red);
}

.stats__text--defence {
  color: var(--purple);
}

.stats-column__spacing {
  padding-left: 12px;
}

.stats-column__spacing--large {
  padding-left: 24px;
}

.exp-bar__container {
  width: 110px;
  height: 20px;

  background: var(--gray);

  position: relative;

  border: 1px solid var(--blue);
  border-radius: 7px;

  .exp-bar__value {
    height: 20px;

    background: var(--blue);

    border-radius: 5px 0px 0px 4px;

    position: absolute;

    transition: width 1s ease-in-out;
  }
}

.stats-hp-bar__container {
  width: 115px;
  height: 20px;

  background: var(--red);

  position: relative;

  border: 1px solid var(--green);
  border-radius: 7px;

  .stats-hp-bar__value {
    height: 20px;

    background: var(--green);

    position: absolute;

    transition: width 1s ease-in-out;
  }
}

.stats-bar__text {
  height: 20px;
  font-size: 22px;
  color: var(--dark-gray);

  font-weight: 500;

  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
}
