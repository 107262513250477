.game-music__button {
  height: 40px;
  width: 45px;

  background-color: var(--dark-gray);

  user-select: none;

  position: relative;

  z-index: 100;

  .game-music__icon {
    font-size: 26px;

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .game-music__diagonal-line--1 {
    height: 2px;
    background: var(--gray);

    transform: rotate(41deg);

    position: absolute;

    transition: all .25s ease-in-out;
  }

  .game-music__diagonal-line--2 {
    height: 2px;
    background: var(--gray);

    transform: rotate(139deg);

    position: absolute;

    transition: all .25s ease-in-out;
  }
}
