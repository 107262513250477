.download-app__container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadein 2s;

  .background-close {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.5);

    cursor: pointer;
  }

  .download-app__box {
    z-index: 10000;

    max-width: 300px;

    padding: 32px;
    margin-bottom: 64px;

    background: var(--dark-gray);

    text-align: center;

    .download-app__title {
      font-size: 20px;

      letter-spacing: 1px;
      line-height: 30px;
    }

    .download-app__buttons {
      padding: 32px 0;

      .ios-icon {
        width: 75%;

        padding: 16px 0 16px;

        cursor: pointer;
      }

      .android-icon {
        width: 75%;

        padding: 16px 0 16px;

        cursor: pointer;
      }
    }

    .close-option {
      font-size: 16px;

      margin: 16px 0 0 0;
    }
  }
}

@media(max-width: 500px) {
  .download-app__container {
    .download-app__box {
      padding: 16px;
      margin-bottom: 32px;

      .download-app__buttons {
        .ios-icon {
          padding: 32px 0 16px;
        }

        .android-icon {
          padding: 16px 0 32px;
        }
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}