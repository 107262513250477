.micro-dialog__container {
  display: flex;
  flex-direction: column;

  z-index: 1;

  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  right: 12px;

  background: var(--dark-gray);
  box-shadow: 1px 2px 50px var(--dark-gray);

  cursor: default;
  user-select: none;

  .micro-dialog__close {
    position: absolute;
    right: 0;
    top: 0;

    font-size: 24px;

    border-left: 1px solid;
    border-bottom: 1px solid;
    border-radius: 2px;

    padding: 7px 12px;
  }
}
