.game-instructions__title {
  font-size: 26px;
  text-align: center;

  padding: 12px 0;
}

.game-instructions__text {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;

  font-size: 20px;
  color: var(--gray);

  letter-spacing: 1px;
  text-transform: none;
  text-align: center;

  padding: 0 36px;

  .game-instructions__native-text {
    padding-left: 8px;
    max-width: 175px;
  }
}

.game-instructions__button {
  padding: 12px 0 16px;

  align-items: center;
}