.settings-dialog__container {
  padding: 16px 0;

  flex: 1;

  justify-content: space-between;
  align-items: center;
}

.settings-dialog__title {
  font-size: 28px;
  font-weight: 500;

  border-bottom: 2px solid;
}