.shop-inventory__container {
  flex: 1;
  justify-content: space-evenly;

  width: 96%;

  padding-top: 4px;

  .shop-inventory__button {
    padding: 4px 16px;
  }
}