.game-text-dialog__container {
  flex: 1;
  justify-content: space-between;
  padding: 16px;
}

.game-text-dialog__text {
  font-family: Montserrat;
  font-size: 18px;
  text-transform: none;

  letter-spacing: 2px;
  line-height: 1.5;
}

.game-text-dialog__button {
  display: flex;
  justify-content: center;
}