.sell-items__container {
  height: 280px;
  width: 315px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  align-self: center;
  align-items: center;
}
