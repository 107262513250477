.snackbar__container {
  background-color: var(--dark-gray);

  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  text-align: center;

  cursor: default;
  user-select: none;

  display: flex;
}

.snackbar__text {
  display: flex;
  flex: 1;

  align-self: center;
  justify-content: center;

  padding: 5px 15px 10px 15px;
}
