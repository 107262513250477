.game-settings__button {
  height: 40px;
  width: 45px;

  background-color: var(--dark-gray);

  margin-top: 12px;

  user-select: none;

  position: relative;

  z-index: 100;

  .game-settings__icon {
    font-size: 26px;

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}