.game-menu__container {
  flex: 1;
  flex-wrap: wrap;

  margin-top: 8px;

  align-items: center;
  align-self: center;

  position: relative;

  padding-left: 2px;
}