.player__animation {
  position: absolute;
  width: 40px;
  height: 40px;
  /* this is for sliding the player to their next location */
  /* NOTE: this needs to be like this otherwise the new bg can load diagonally and it looks weird */
  transition: left .5s ease-in-out 0s, top .5s ease-in-out 0s;
}

@keyframes movePlayer {
  100% {
    background-position-x: 320px;
  }
}

.sword__slash {
  position: absolute;
  width: 40px;
  height: 40px;

  z-index: 1000;

  /* this is for animating the 'steps' */
  animation: swordSlash .35s steps(8);
  animation-direction: reverse;
  background-position-x: 0px;
}

@keyframes swordSlash {
  100% {
    background-position-x: 320px;
  }
}

.monster__slash {
  position: absolute;
  width: 40px;
  height: 40px;

  z-index: 1000;

  top: 0;
  left: 0;
  /* this is for animating the 'steps' */
  animation: swordSlash .35s steps(8);
  animation-direction: reverse;
  animation-delay: .25s;

  background-position-x: 0px;
}
