.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;

  padding-top: 48px;

  &:after {
    content: " ";
    display: block;
    
    width: 64px;
    height: 64px;

    border-radius: 50%;
    border: 12px solid var(--gray);
    border-color: var(--gray) transparent var(--gray) transparent;

    animation: spinning 1.2s linear infinite;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}