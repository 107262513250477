.game-start__title {
  font-size: 26px;
  justify-content: center;

  padding: 14px 0 0;
  margin-left: 16px;
}

.game-start__text {
  flex: 1;

  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;

  padding: 48px 32px 0;
}

.game-start__button {
  padding: 12px 0 16px;

  align-items: center;
}