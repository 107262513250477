.confirm-dialog__container {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: var(--dark-gray);
  box-shadow: 1px 2px 50px var(--dark-gray);

  cursor: default;
  user-select: none;

  .confirm-dialog__text {
    display: flex;
    flex: 1;
    align-items: center;

    font-size: 22px;
    text-align: center;

    padding: 16px;
  }

  .confirm-dialog__buttons {
    justify-content: space-around;
    padding-bottom: 16px;
  }
}