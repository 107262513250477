.shop-keep-animated {
  transform: scale(0.75);

  height: 200px;
  width: 210px;

  animation: moveShopKeep .75s steps(6) infinite;
  background-position-x: 0px;
}

@keyframes moveShopKeep {
  100% {
    background-position-x: 1260px;
  }
}
