.game-select__title {
  justify-content: center;

  padding: 16px 16px 0;

  font-size: 26px;
}

.game-select__text {
  flex: 1;

  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;

  justify-content: center;

  padding: 0 32px;
}

.game-select__button {
  padding: 16px;

  margin-right: 50px;

  align-items: center;
}

.game-select__flame--1 {
  position: absolute;
  bottom: 68px;
  right: 52px;
}

.game-select__flame--2 {
  position: absolute;
  bottom: 142px;
  right: 52px;
}
