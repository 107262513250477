.game-win__title {
  font-size: 26px;

  padding: 24px 16px 0;

  justify-content: center;
}

.game-win__text {
  flex: 1;

  padding: 16px;

  font-size: 20px;
  text-align: center;

  line-height: 36px;
  letter-spacing: 1px;
}

.game-win__button {
  padding-bottom: 16px;

  justify-content: center;
}
